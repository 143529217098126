import React, { useContext } from 'react';
import styles from './DarkModeButton.module.css';
import ThemeContext from './ThemeContext';
import DarkMode from '../images/dark-mode.png';
import LightMode from '../images/light-mode.png';

const DarkModeButton = () => {

    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <>
            <img 
                className={styles["light-mode-btn" ]}
                src={theme === 'dark' ? DarkMode : LightMode} 
                alt={theme === 'dark' ? "Switch to Light Mode" : "Switch to Dark Mode"}
                onClick={toggleTheme}
            />
        </>
    )
}

export default DarkModeButton;