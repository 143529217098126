import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PortfolioPage from './pages/Portfolio';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';


import {
  createBrowserRouter,
  RouteProvider,
  Route,
  RouterProvider,
} from "react-router-dom";

const router= createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },

  {
    path: "portfolio",
    element: <PortfolioPage/>,
  },

  {
    path: "about",
    element: <AboutPage/>,
  },

  {
    path:"contact",
    element: <ContactPage/>,
  },
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);
