import React from 'react';
import './index.css';
import Header from './components/Header';
import Content from './components/Content';
import ThemeProvider from './components/ThemeProvider';

function App() {
  return (
    <>
      <ThemeProvider>
        <Header/>
        <Content/>
      </ThemeProvider>
    </>
  )
}
export default App;
