import React, { useState } from 'react';
import styles from '../components/MobileMenu.module.css';
import brain from '../images/brain.svg';
import wrench from '../images/wrench.svg';
import arrows from '../images/arrows.svg';
import downArrow from '../images/down-arrow.svg';
import upArrow from '../images/arrow-up.svg';
import htmlIcon from '../images/html.svg';
import cssIcon from '../images/css-3.svg';
import javascriptIcon from '../images/javascript.svg';
import sassIcon from '../images/sass.png'
import reactIcon from '../images/atom.svg';
import bootstrapIcon from '../images/bootstrap.svg';
import tailwindIcon from '../images/tailwind.svg'
import pythonIcon from '../images/python.svg';
import wordpressIcon from '../images/wordpress-logo.svg';
import squarespaceIcon from '../images/squarespace.png';
import wixIcon from '../images/wix.svg';
import nodejsIcon from '../images/nodejs.png';
import expressjsIcon from '../images/expressjs.png';
import mongodbIcon from '../images/mongodb.png';



const MobileMenu = () => {
    const [expandedTab, setExpandedTab] = useState(null);

    const toggleTab = (tabName) => {
        if (expandedTab === tabName) {
            setExpandedTab(null);
        } else {
            setExpandedTab(tabName);
        }
    };

    const SkillsContent = () => (
        <div className={styles["skills-content"]}>
            {/* <h3 className={styles["title"]}>Front-end</h3> */}
            <div className={styles["front-end"]}>
                <div className={styles["frontend-images"]}>
                    <img className={styles["content-icon"]} src={htmlIcon} alt="html icon" />
                    <img className={styles["content-icon"]} src={cssIcon} alt="css icon" />
                    <img className={styles["javascript-icon"]} src={javascriptIcon} alt="javascript icon" />
                    <img className={styles["content-icon"]} src={sassIcon} alt="sass icon" />
                    <img className={styles["content-icon"]} src={reactIcon} alt="react icon" />
                    <img className={styles["content-icon"]} src={bootstrapIcon} alt="bootstrap icon" />
                    <img className={styles["content-icon"]} src={tailwindIcon} alt="tailwind icon" />
                </div>
            </div>
            <div className={styles["back-end"]}>
                {/* <h3 className={styles["title"]}>Back-end</h3> */}
                <div className={styles["backend-images"]}>
                    <img className={styles["content-icon"]} src={pythonIcon} alt="python icon" />
                    <img className={styles["content-icon"]} src={nodejsIcon} alt="nodejs icon" />
                    <img className={styles["content-icon"]} src={expressjsIcon} alt="expressjs icon" />
                    <img className={styles["content-icon"]} src={mongodbIcon} alt="mongodb icon" />
                </div>
            </div>
            <div className={styles["cms"]}>
                {/* <h3 className={styles["title"]}>CMS (Content Management System)</h3> */}
                <div className={styles["cms-images"]}>
                    <img className={styles["content-icon"]} src={wordpressIcon} alt="wordpress icon" />
                    <img className={styles["content-icon"]} src={squarespaceIcon} alt="squarespace icon" />
                    <img className={styles["content-icon"]} src={wixIcon} alt="wix icon" />
                </div>
            </div>
        </div>
    );
    
    // const ToolsContent = () => (
    //     <div className={styles["tools-content"]}>
    //         <div className={styles["Design"]}>
    //             <h3 className={styles["title"]}>Dev Tools</h3>
    //             <div className={styles["dev-tools"]}>
    //                 <p>Visual Studio Code</p>
    //                 <p>Git</p>
    //                 <p>GitHub</p>
    //             </div>

                
    //             <h3 className={styles["title"]}>Design</h3>
    //             <div className={styles["design-tools"]}>
    //                 <p>Adobe XD</p>
    //                 <p>Adobe Illustrator</p>
    //                 <p>Adobe Photoshop</p>
    //                 <p>Figma</p>
    //             </div>
    //         </div>
    //     </div>
    // );
    
    // const HobbiesContent = () => (
    //     <div className={styles["hobbies-content"]}>
    //         <div className={styles["Hobbies"]}>
    //             <h3 className={styles["title"]}></h3>
    //         </div>
    //     </div>
    // );
    

    const getContentForTab = (tabName) => {
        switch(tabName) {
            case 'Skills':
                return <SkillsContent />;
            // case 'Tools':
            //     return <ToolsContent />;
            // case 'Hobbies':
            //     return <HobbiesContent />;
            default:
                return null;
        }
    };

    return (
        <div className={styles["mobile-dropdown"]}>
            {["Skills"].map((tabName, index) => (
                <div key={tabName} className={styles["tab"]} data-tab={tabName}>
                    <div className={styles["tab-header"]} onClick={() => toggleTab(tabName)}>
                        <img src={[brain, wrench, arrows][index]} alt={`${tabName} icon`} className={styles["icon"]} />
                        {tabName}
                        <img src={expandedTab === tabName ? upArrow : downArrow} alt="Toggle arrow" className={styles["arrow"]} />
                    </div>
                    <div className={styles["content-container"]}>
                        {expandedTab === tabName && (
                            <div className={styles["tab-content"]}>
                                {getContentForTab(tabName)}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
    
};

export default MobileMenu;