import React, { useContext } from 'react';
import Data from '../components/Data';
import Header from '../components/Header';
import styles from './Portfolio.module.css';
import Footer from '../components/Footer';
import Card from '../components/Card';
import Card2 from '../components/Card2';
import Card3 from '../components/Card3';
import swipeArrow from '../images/arrow-left.png'
import MobileCarousel from '../components/MobileCarousel';
import 'animate.css/animate.min.css';
import { motion } from 'framer-motion';
import { Fade } from 'react-awesome-reveal';
import ThemeProvider from '../components/ThemeProvider';
import DarkModeButton from '../components/DarkModeButton';


function PortfolioPage () {
    return (
        <ThemeProvider>
            <motion.div
            className="container text-center bg-black"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{ opacity: 0}}
            transition={{ duration: 1 }}
            >
            <div className={styles["container"]}>
                <div className={styles["header-section"]}>
                    <Header/>
                </div>
            
                <div className={styles["main-content"]}>
                    <h1 className={styles["title"]}>Projects</h1>
                    <div className={styles["project-grid"]}>
                    <Fade 
                    cascade
                    damping={0.5}
                    delay={500}
                    triggerOnce={true}>
                        {Data.map(card => (
                            <Card 
                                key={card.title}
                                imageSrc={card.imageSrc}
                                title={card.title}
                                subTitle={card.subTitle}
                                description={card.description}
                                technologies={card.technologies}
                                liveLink={card.liveLink}
                                codeLink={card.codeLink}
                            /> 
                        ))} 
                    </Fade>
                    </div>

                    <div className={styles['mobile-carousel']}>
                        <MobileCarousel/>  
                        <p className={`${styles["swipe-instruction"]} animate__animated animate__shakeX animate__slower`}>
                            Swipe to navigate
                            <img src={swipeArrow} alt="left arrow" className={styles["swipe-arrow"]}></img>
                        </p>
                    </div>
                </div>
                <div className={styles["footer-section"]}>
                    <DarkModeButton/>
                </div>
            </div>
            </motion.div>
        </ThemeProvider>
    )
}
export default PortfolioPage;
