import React, { useState, useEffect } from 'react';
import styles from './Button.module.css';
import ContactModal from './ContactModal';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const Button = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // Instantiate useNavigate hook

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleContactClick = () => {
    if(windowWidth > 1024) {
      // Open modal
      setModalOpen(true);
    } else {
      // Navigate to contact page
      navigate('/contact'); // Directly call navigate with the path
    }
  };

  return (
    <>
      <button onClick={handleContactClick} className={styles["contact-btn"]}>
        Contact Me
      </button>
      {isModalOpen && <ContactModal closeModal={() => setModalOpen(false)} />}
    </>
  );
};

export default Button;