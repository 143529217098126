import React, { useEffect, useState } from 'react';
import styles from './Content.module.css';
import Footer from './Footer';
// import HeroImage from '../images/HeroPortrait.svg';
// import HeroAnimated from '../images/heroAnimated.svg';
import Hero from './Hero';
// import HeroAnimated from '../images/Hero PortraitCSS.svg';
// import HeroComponent from './HeroComponent';
import Button from './Button';
import { Fade } from 'react-awesome-reveal';
import { Slide } from 'react-awesome-reveal';
import { motion } from 'framer-motion';

const Content = () => {
  
  return (
    <motion.div
        className="container text-center bg-black"
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{ opacity: 0}}
        transition={{ duration: 1 }}
        >
    <main className={styles["main-container"]}>
      <div className={styles["column-right"]}>
        <div className={styles["hero-image"]}>
          <Hero/>
        </div>
        {/* <HeroAnimated className={styles["hero-image"]} /> */}
        {/* <img className={styles["hero-image"]} src={HeroAnimated} alt="illustration" /> */}
      </div>
      <div className={styles["column-left"]}>
        <div className="typewriter">
          <h1 className={`${styles["title"]}`}>Hi, I'm Lia.</h1>
        </div>
        <div className={styles["vertical-line"]}></div>
        <p className={styles["main-text"]}> 
          I'm a web developer with a passion for design.
          <br />
          I enjoy turning lines of code into beautiful user experiences.
        </p>
        <Button />
      </div>
      
      <div className={styles["footer-section"]}>
        <Footer />
      </div>
    </main>
  </motion.div>
  );
};

export default Content;
