import React, { useState, useEffect, useRef, useContext } from 'react';
import ThemeProvider, { ThemeContext } from './ThemeProvider';
import { NavLink, useLocation } from 'react-router-dom';
import { Twirl as Hamburger } from 'hamburger-react';
import styles from './Sidebar.module.css';
import gsap from 'gsap';
import { Fade } from 'react-awesome-reveal';

const Sidebar = () => {

  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const sidebarRef = useRef(null);
  const hamburgerRef = useRef(null);

  const toggleSidebar = () => {
    setOpen(!isOpen);
  };

  const { theme } = useContext(ThemeContext);


  useEffect(() => {
    // Remove the "active" class from all links
    document.querySelectorAll(`.${styles['active-link']}`).forEach(link => {
      link.classList.remove(styles.active);
    });

    // Add the "active" class to the current link
    const currentLink = document.querySelector(`[to="${location.pathname}"]`);
    if (currentLink) {
      currentLink.classList.add(styles.active);
    }
    
    // Add event listener to close sidebar on outside click
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (isOpen) {
      // GSAP animation to bounce the hamburger button
      gsap.to(hamburgerRef.current, {
        duration: 0.2,
        y: -10,
        yoyo: true,
        repeat: 1,
      });
    }
  }, [isOpen]);

  const activeLinkStyle = {
    color: 'white',
    fontWeight: '600',
  };

  return (
    <ThemeProvider>
      <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`} ref={sidebarRef}>
        <div className={styles['menu-button']} ref={hamburgerRef}>
          <Hamburger
            size={40}
            toggled={isOpen}
            toggle={toggleSidebar}
            color={isOpen ? '#2E2E2E' : '#FF9AA5'}
            direction="left"
            distance="sm"
            rounded
          />
        </div>
        <nav className={styles.menu}>
          <Fade 
          cascade
          damping={0.3}
          >
          
          <ul>
            <li>
              <NavLink
                to="/"
                className={`${styles['link']} ${location.pathname === '/' && styles.active}`}
                style={location.pathname === '/' ? activeLinkStyle : {}}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
                className={`${styles['link']} ${location.pathname === '/portfolio' && styles.active}`}
                style={location.pathname === '/portfolio' ? activeLinkStyle : {}}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={`${styles['link']} ${location.pathname === '/about' && styles.active}`}
                style={location.pathname === '/about' ? activeLinkStyle : {}}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={`${styles['link']} ${location.pathname === '/contact' && styles.active}`}
                style={location.pathname === '/contact' ? activeLinkStyle : {}}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          </Fade>
        </nav>
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;





