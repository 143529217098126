import React, { useContext } from 'react';
import ThemeContext from './ThemeContext';
import styles from './Footer.module.css';
import LinkedInImage from '../images/linkedin.svg';
import LinkedInLight from '../images/linkedinLight.png';
import GitHubImage from '../images/github-sign.svg';
import GitHubLight from '../images/github-sign-light.png';
import ShareIcon from '../images/share.svg';
import ShareLight from '../images/shareLight.png';
import DarkMode from '../images/dark-mode.png';
import LightMode from '../images/light-mode.png';

const Footer = () => {

    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <footer className={styles["footer-links"]}>
            
            <div className={styles["column-left"]}>
                <div className={styles["profile-img"]}></div>
                <div className={styles["socials"]}>
                    <div className={styles["linkedin-links"]}>
                        <a href="https://linkedin.com/in/keliasmith" target="_blank" rel="noreferrer">
                        <img className={styles["linkedin-icon"]} src={theme === 'dark' ? LinkedInImage : LinkedInLight} alt="linkedin icon"/>
                        </a>
                        
                        <a href="https://linkedin.com/in/keliasmith" target="_blank" rel="noreferrer" className={styles["link"]}>LinkedIn</a>
                        
                        <a href="https://linkedin.com/in/keliasmith" target="_blank" rel="noreferrer">
                            <img className={ `${styles.share} ${styles.link}`} src={theme === 'dark' ? ShareIcon : ShareLight} alt="share icon"/>
                        </a>
                    </div>
                    
                    <div className={styles["github-links"]}>
                        <a href="https://github.com/liaberries" target="_blank" rel="noreferrer">
                        <img className={styles["github-icon"]} src={theme === 'dark' ? GitHubImage : GitHubLight} alt="github icon"/>
                        </a>
                
                        <a href="https://github.com/liaberries" target="_blank" rel="noreferrer" className={styles["link"]}>Github</a>

                        <a href="https://github.com/liaberries" target="_blank" rel="noreferrer">
                            <img className={ `${styles.share} ${styles.link}`} src={theme === 'dark' ? ShareIcon : ShareLight} alt="share icon"/>
                        </a>
                    </div>
                </div>
            </div>

        
            <div className={styles["column-right"]}>
                <img 
                    className={styles["light-mode-btn"]} 
                    src={theme === 'dark' ? DarkMode : LightMode} 
                    alt={theme === 'dark' ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    onClick={toggleTheme}
                />
            </div>


            <div className={styles["mobile-footer"]}>
                <div className={styles["mobile-social"]}>
                    <a href="https://linkedin.com/in/keliasmith" target="_blank" rel="noreferrer">
                        <img className={styles["linkedin-mobile"]} src={theme === 'dark' ? LinkedInImage : LinkedInLight} alt="linkedin icon"/>
                    </a>

                    <a href="https://github.com/liaberries" target="_blank" rel="noreferrer">
                        <img className={styles["github-mobile"]} src={theme === 'dark' ? GitHubImage : GitHubLight} alt="github icon"/>
                    </a>
                </div>
                
                <div className={styles["mobile-dark-mode"]}>
                    <img 
                    className={styles["light-mode-btn"]} 
                    src={theme === 'dark' ? DarkMode : LightMode} 
                    alt={theme === 'dark' ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    onClick={toggleTheme}
                    />
                </div>
                
                
            </div>
        </footer>

    )  
}
export default Footer;