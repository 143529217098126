import React, {useContext} from 'react';
import { ThemeContext } from './ThemeProvider';
import styles from './Header.module.css';
import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Twirl as Hamburger } from 'hamburger-react';
import 'animate.css';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom'; // Import useLocation
import ContactModal from './ContactModal'


const Header = () => {
  const location = useLocation(); // Get the current location path
  const { theme } = useContext(ThemeContext);


  const [isModalOpen, setModalOpen] = useState(false); // Add state for modal
  const toggleModal = () => setModalOpen(!isModalOpen); // Function to toggle the modal
  const handleSubmit = (event) => {
    event.preventDefault(); // Handle
  }

  return (
    <header className={styles["navigation"]}>
      <Sidebar />
      <Link to="/" className={`${styles["logo-link"]}`}>kelia.dev</Link>
      <div className={styles["desktop-nav"]}>
        <nav className={styles["links"]}>
          <Link to="/" className={`${location.pathname === '/' ? styles.active : ''} ${styles["nav-link"]}`}>Home</Link>
          <Link to="/portfolio" className={`${location.pathname === '/portfolio' ? styles.active : ''} ${styles["nav-link"]}`}>Portfolio</Link>
          <Link to="/about" className={`${location.pathname === '/about' ? styles.active : ''} ${styles["nav-link"]}`}>About</Link>
          <button onClick={toggleModal} className={styles["contact-button"]}>Contact</button>
        </nav>
      </div>
      {isModalOpen && <ContactModal closeModal={toggleModal} />} 
    </header>
  )
}

export default Header;
