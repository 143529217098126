import React from 'react';
import { useSwipeable } from 'react-swipeable';
import Card from './Card';
import styles from './MobileCarousel.module.css';
import Data from './Data';

const MobileCarousel = () => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    const wrapperElement = wrapperRef.current;
    if (wrapperElement) {
      wrapperElement.addEventListener('touchmove', preventDefault, { passive: false });
    }
    return () => {
      if (wrapperElement) {
        wrapperElement.removeEventListener('touchmove', preventDefault);
      }
    };
  }, []);

  const moveSlide = (direction) => {
    let newIndex = (slideIndex + direction + Data.length) % Data.length;
    setSlideIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => moveSlide(1),
    onSwipedRight: () => moveSlide(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // Correct offset calculation
  const offset = -slideIndex * 100; // Each slide is 100% of the width
  
  return (
    <div className={styles.carouselContainer} {...handlers}>
      <div className={styles.carouselWrapper} style={{ transform: `translateX(${offset}%)` }} ref={wrapperRef}>
        {Data.map((item, index) => (
          <div className={styles.carouselSlide} key={index}>
            <Card {...item} />
          </div>
        ))}
      </div>
  
      <button id={styles.prevSlide} className={styles.carouselButton} onClick={() => moveSlide(-1)}></button>
      <button id={styles.nextSlide} className={styles.carouselButton} onClick={() => moveSlide(1)}></button>
    </div>
  );
}

export default MobileCarousel;

