import React, { useContext } from 'react';
import styles from './Card.module.css';
import ThemeContext from '../components/ThemeContext';

const Card = ({ imageSrc, title, subTitle, description, technologies, liveLink, codeLink }) => {
    
    const { theme } = useContext(ThemeContext);

    const cardContainerClass = theme === 'dark' ? styles["card-container"] : styles["card-container-light"];
    const titleClass = theme === 'dark' ? styles["title"] : styles["title-light"];
    const subTitleClass = theme === 'dark' ? styles["sub-title"] : styles["sub-title-light"];
    const descriptionClass = theme === 'dark' ? styles["description"] : styles["description-light"]
    const spanClass = theme === 'dark' ? styles["span"] : styles["span-light"];

    return (
        <div className={`${styles["card-container"]} ${cardContainerClass}`}>
            <div className={styles["card-image-container"]}>
                <img src={imageSrc} className={styles["card-image"]} alt="website preview"/>
            </div>
            

            <div className={styles["card-content"]}>
                <h2 className={`${styles["title"]} ${titleClass}`}>{title}</h2>
                <h3 className={`${styles["sub-title"]} ${subTitleClass}`}>{subTitle}</h3>
                <p className={`${styles["description"]} ${descriptionClass}`}>{description}</p>
                
                <span className={`${styles["span"]} ${spanClass}`}>Technologies:</span>
                <div className={styles["tags-container"]}>
                    
                    <div className={styles["tags"]}>
                        <ul>
                            {technologies && Array.isArray(technologies) && technologies.map((tech, index) => (
                            <li key={index}>{tech}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={styles["btn-container"]}>
                    <a href={liveLink} className={styles["view-live-btn"]} target="_blank" rel="noreferrer">View Live Site</a>
                    {codeLink && <a href={codeLink} className={styles["view-source-btn"]}>View Source Code</a>}
                </div>
            </div>
        </div>
    )
};
export default Card;