import React, { useContext, useState } from 'react';
import ThemeContext from '../components/ThemeContext';
import styles from './Contact.module.css';
import Header from '../components/Header';
import LinkedInImage from '../images/linkedin.svg';
import LinkedInLight from '../images/linkedinLight.png';
import GithubImage from '../images/github-sign.svg';
import GitHubLight from '../images/github-sign-light.png';

function ContactPage() {
    const { theme } = useContext(ThemeContext);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Set the submitting state

        const formData = new FormData(event.target);

        try {
            const response = await fetch('https://portfolio-contact-form-9828ab52fe92.herokuapp.com/send-email', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                // Handle the successful submission here
                setSubmitted(true);
            } else {
                // Handle server errors here
                console.error('Server responded with status:', response.status);
            }
        } catch (error) {
            // Handle network errors here
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsSubmitting(false); // Reset the submitting state
        }
    };

    return (
        <>
            <Header/>
            {!isSubmitted ? (
                <div className={styles["form-container"]}>
                    <h1 className={styles["heading"]}>Get in Touch</h1>
                    <form onSubmit={handleSubmit} className={styles["content"]}>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" required></textarea>

                        <button 
                            type="submit" 
                            className={styles["submitButton"]}
                            disabled={isSubmitting} // Disable the button while submitting
                        >
                            {isSubmitting ? 'Sending...' : 'Submit'} 
                        </button>
                        
                        {/* Social links and direct email */}
                        <div className={styles["socialLinks"]}>
                            <h3 className={styles["connect"]}>Connect with me:</h3>
                            <div className={styles["image-container"]}>
                                <a href="https://linkedin.com/in/keliasmith" target="_blank" rel="noreferrer">
                                    <img className={styles["linkedIn"]} src={theme === 'light' ? LinkedInImage : LinkedInLight} alt="LinkedIn icon"/>
                                </a>
                                <a href="https://github.com/liaberries" target="_blank" rel="noreferrer">
                                    <img className={styles["github"]} src={theme === 'light' ? GithubImage : GitHubLight} alt="GitHub icon"/>
                                </a>                                
                            </div>
                            <div className={styles["email-direct"]}>
                                <h3>Feel free to email me directly at <a href="mailto:hello@kelia.dev" className={styles["email-link"]}>hello@kelia.dev</a>.</h3>
                            </div>
                        </div>
                    </form> 
                </div>
            ) : (
                // Submitted state content
                <div className={styles["thank-you-container"]}>
                    <h2 className={styles["success-heading"]}>Thank you for reaching out!</h2>
                    <p className={styles["success-message"]}>Your message has been received. I will get back to you shortly.</p>
                    <a href="https://kelia.dev" className={styles["btn-link"]}>
                        <button type="button" className={styles["submitButton"]} onClick={() => setSubmitted(false)}>Close</button>
                    </a>
                </div>
            )}
        </>
    );
}

export default ContactPage;

