import React, { useContext, useState } from 'react';
import styles from './ContactModal.module.css';
import ThemeContext from './ThemeContext';
import LinkedInImage from '../images/linkedin.svg';
import LinkedInLight from '../images/linkedinLight.png';
import GithubImage from '../images/github-sign.svg';
import GitHubLight from '../images/github-sign-light.png';

const ContactModal = ({ closeModal }) => {
  const { theme } = useContext(ThemeContext);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submit event triggered');
    setIsSubmitting(true); // Disable the submit button and show feedback
    
    const formData = new FormData(event.target);

    for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }

    try {
      const response = await fetch('https://portfolio-contact-form-9828ab52fe92.herokuapp.com/send-email', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Handle the successful submission here
        setSubmitted(true);
      } else {
        // Handle server errors here
        console.error('Server responded with status:', response.status);
      }
    } catch (error) {
      // Handle network errors here
      console.error('There was a problem with the fetch operation:', error);
    } finally {
        setIsSubmitting(false);
    }
  };

  return (
    <div className={styles["modalOverlay"]}>
      {!isSubmitted ? (
        <div className={styles["modal"]}>
          <div className={styles["modalHeader"]}>
            <h2 className={styles["heading"]}>Get in Touch</h2>
            <button onClick={closeModal} className={styles["closeButton"]}>&times;</button>
          </div>
          <form onSubmit={handleSubmit} className={styles["modalContent"]}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required></textarea>

            <button 
            type="submit" 
            className={styles["submitButton"]}
            disabled={isSubmitting} //
            >
                {isSubmitting ? 'Sending...' : 'Submit'}
            </button>

            <div className={styles["socialLinks"]}>
              <h3 className={styles["connect"]}>Connect with me:</h3>
              <div className={styles["image-container"]}>
                <a href="https://linkedin.com/in/keliasmith" target="_blank" rel="noreferrer">
                  <img className={styles["linkedIn"]} src={theme === 'dark' ? LinkedInImage : LinkedInLight} alt="LinkedIn icon"/>
                </a>

                <a href="https://github.com/liaberries" target="_blank" rel="noreferrer">
                  <img className={styles["github"]} src={theme === 'dark' ? GithubImage : GitHubLight} alt="GitHub icon"/>
                </a>
              </div>

              <div className={styles["email-direct"]}>
                <h3>Feel free to email me directly at <a href="mailto:hello@kelia.dev" className={styles["email-link"]}>hello@kelia.dev</a>.</h3>
              </div>
            </div>
          </form>
        </div>
      ) : (
        // Submitted state content
        <div className={styles["modal"]}>
          <div className={styles["modalHeader"]}>
            <h2 className={styles["success-heading"]}>Thank you for reaching out!</h2>
            <button onClick={closeModal} className={styles["closeButton"]}>&times;</button>
          </div>
          <div className={styles["modalContent"]}>
            <p className={styles["success-message"]}>Your message has been received. <br/><br/> I will get back to you shortly.</p>
            <button onClick={closeModal} className={styles["submitButton"]}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactModal;
