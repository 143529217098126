import React, { useContext } from 'react';
import ThemeContext from '../components/ThemeContext';
import ThemeProvider from '../components/ThemeProvider';
import styles from './About.module.css';
import Header from '../components/Header';
import Button from '../components/Button';
import headshot3 from '../images/headshotSmall.png';
import computer from '../images/Computer.svg';
import Dropdown from '../components/Dropdown'
import MobileMenu from '../components/MobileMenu';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import DarkModeButton from '../components/DarkModeButton';
import Skills from '../components/Skills';


function AboutPage () {

    const { theme } = useContext(ThemeContext);

    return (
        <ThemeProvider>
            <motion.div
            className="container text-center bg-black"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{ opacity: 0}}
            transition={{ duration: 1 }}
            >
                <div className={styles["container"]}>
                    <div className={styles["header-section"]}>
                        <Header/>
                    </div>
                   
                    <div className={styles["main-content"]}>
                        <div className={styles["column-left"]}>
                            <div className={styles["image-container"]}>
                                <img className={styles["headshot"]} src={headshot3} alt="headshot"></img>
                            </div>
                            <a href="https://kelia.dev/resume.pdf" target="_blank" className={`${styles['download-btn']} ${styles['desktop-btn']}`} rel="noreferrer"> ➔ Resume</a>
                            {/* <div className={styles["desktop-dropdown"]}>
                                    <Dropdown/>
                            </div> */}
                        </div>  {/*End of column-left */}

                        <div className={styles["column-right"]}>
                        <h1 className={styles["title"]}>About Me</h1>
                            <p className={styles["bio-paragraph-one"]}>
                            I'm a web developer from Long Beach, California. I enjoy blending art and design with technology 
                            to create websites and applications that are both visually appealing, well-structured and organized.
                            </p>

                            <p className={styles["bio-paragraph-two"]}>
                            In my freetime, my commitment to continuous self-development and growth drives me to explore new technologies and methods I can use to create engaging user experiences. 
                            Outside of coding, you can find me immersed in a painting I'm working on, or tending to my crops on Stardew Valley!
                            </p>
                            <div className={styles["details"]}>
                                <div className={styles["name"]}>
                                    <p className={styles["detail-title"]}>Name</p>
                                    <p className={styles["myName"]}>Kelia "Lia" Smith</p>
                                </div>
                                <div className={styles["education"]}>
                                    <p className={styles["detail-title"]}>Education</p>
                                    <p className={styles["school"]}>Los Angeles City College - AA</p>
                                    <p className={styles["degree"]}>Natural Sciences & Mathematics</p>
                                </div>
                                <div className={styles["employment"]}>
                                    <p className={styles["detail-title"]}>Employment</p>
                                    <p className={styles["status"]}>Open</p>

                                    <div className={styles["skillset"]}>
                                        <h2 className={styles["skillset-title"]}>My skills</h2>
                                        <Skills/>
                                    </div>
                                </div>

                                {/* <a href={`${process.env.PUBLIC_URL}/resume.pdf`} className={`${styles['download-btn']} ${styles['mobile-btn']}`}>➔ Resume</a> */}
                                <a href="https://kelia.dev/resume.pdf" target="_blank" className={`${styles['download-btn']} ${styles['mobile-btn']}`} rel="noreferrer">➔ Resume</a>
                            </div>
                            <div className={styles["other-content"]}>
                                <div className={styles["computer-container"]}>
                                    <img src={computer} className={styles["computer"]} alt="computer"></img>
                                </div>
                            
                                <div className={styles["mobile-dropdown"]}>
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className={styles["dark-mode-container"]}>
                                <DarkModeButton/>
                            </div>
                        </div> {/* End of column-right */}
                    </div> {/* End of main content */}
                </div> {/*  End of container  */}
            </motion.div>
        </ThemeProvider>
    )
}

export default AboutPage;