import preview from '../images/previewbig.png';
import snippet from '../images/snippet.png';
import preview2 from '../images/gmmcarepreview.png';

const Data = [
    {
        imageSrc: preview,
        title: 'Driven Mobile Notary Services',
        subTitle: 'Company Website',
        description: 'For this client project, I designed and developed a professional website for a notary public in Las Vegas, Nevada. Features include mobile responsiveness and easy service booking through WordPress integration.',
        technologies: ['HTML5', 'CSS3', 'ReactJS', 'Node.js', 'Express.js', 'Heroku', 'WordPress', 'Adobe XD'],
        liveLink: 'https://drivennotaryservices.com',
    },
    {
        imageSrc: preview2,
        title: 'GMMCARE',
        subTitle: 'Company Website',
        description: 'A comprehensive website for GMMCARE, a healthcare services provider. Key features include detailed service information and a contact form to enhance user engagement and accessibility.',
        technologies: ['HTML', 'CSS', 'ReactJS', 'Node.js', 'Express.js', 'Adobe XD'],
        liveLink: 'https://gmmcare.com',
    },
    // {
    //     imageSrc: snippet,
    //     title: 'Title',
    //     subTitle: 'Sub-title',
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    //     technologies: ['Sample Tag', 'Sample Tag', 'Sample Tag', 'Sample Tag', 'Sample Tag', 'Sample Tag', 'Sample Tag'],
    //     liveLink: '#',
    //     codeLink: '#'
    // },
]

export default Data;