// ThemeProvider.js
import React, { useState, useEffect, createContext } from "react";
import ThemeContext from "./ThemeContext"; // Ensure this is the correct path to your ThemeContext.js

const ThemeProvider = ({ children }) => {
  // const [theme, setTheme] = useState("dark");
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

  useEffect(() => {
    // Get theme from local storage or default to 'dark'
    const savedTheme = localStorage.getItem("theme") || "dark";
    setTheme(savedTheme);
    document.documentElement.setAttribute("data-theme", savedTheme);
  }, []);

  

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    console.log("Current theme:", theme, "New theme:", newTheme); // debugging line
    
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Re-export ThemeContext for easy access
export { ThemeContext };
export default ThemeProvider;
