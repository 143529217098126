import React from 'react';
import styles from './Skills.module.css';
import htmlIcon from '../images/html.svg';
import cssIcon from '../images/css-3.svg';
import javascriptIcon from '../images/javascript.svg';
import sassIcon from '../images/sass.png';
import reactIcon from '../images/atom.svg';
import bootstrapIcon from '../images/bootstrap.svg';
import tailwindIcon from '../images/tailwind.svg';
import pythonIcon from '../images/python.svg';
import wordpressIcon from '../images/wordpress-logo.svg';
import squarespaceIcon from '../images/squarespace.png';
import wixIcon from '../images/wix.svg';
import nodejsIcon from '../images/nodejs.png';
import expressjsIcon from '../images/expressjs.png';
import mongodbIcon from '../images/mongodb.png';

 
const HoverIcon = ({ src, alt, tooltipText }) => {
  return (
    <div className={styles["icon-container"]} style={{ position: 'relative' }}>
      <img className={styles["content-icon"]} src={src} alt={alt} />
      <div className={styles["tooltip"]}>{tooltipText}</div>
    </div>
  );
};

const Skills = () => (
  <div className={styles["skills-container"]}>
        <div className={styles["front-end"]}>
            <HoverIcon src={htmlIcon} alt="html icon" tooltipText="HTML" />
            <HoverIcon  src={cssIcon} alt="css icon" tooltipText="CSS" />
            <HoverIcon  src={javascriptIcon} alt="javascript icon" tooltipText="Javascript" />
            <HoverIcon  src={sassIcon} alt="sass icon" tooltipText="Sass" />
            <HoverIcon  src={reactIcon} alt="react icon" tooltipText="React" />
            <HoverIcon  src={bootstrapIcon} alt="bootstrap icon" tooltipText="Bootstrap" />
            <HoverIcon  src={tailwindIcon} alt="tailwind icon" tooltipText="Tailwind" />
        </div>

        <div className={styles["back-end"]}>
          <HoverIcon src={pythonIcon} alt="python icon" tooltipText="Python" />
          <HoverIcon src={nodejsIcon} alt="nodejs icon" tooltipText="Node.js" />
          <HoverIcon  src={expressjsIcon} alt="expressjs icon" tooltipText="Express.js" />
          <HoverIcon  src={mongodbIcon} alt="mongodb icon" tooltipText="MongoDB" />
          

        </div>

        <div className={styles["CMS"]}>
            <HoverIcon  src={wordpressIcon} alt="wordpress icon" tooltipText="Wordpress" />
            <HoverIcon  src={squarespaceIcon} alt="squarespace icon" tooltipText="Squarespace" />
            <HoverIcon  src={wixIcon} alt="wix icon" tooltipText="Wix" />
        </div>

        
            
  </div>
);

export default Skills;


